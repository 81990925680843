import { mapActions } from "vuex";
import registerApi from "../../apis/liff/v2/register";

const MODULE_CODE = 'member_register'

export default {
  methods: {
    ...mapActions({
      fetchMe: 'liffAuth/fetchMe',
    }),
    async checkRegistered() {
      // alert 目前網址長相
      console.log(window.location.href);

      let params = {};

      params = Object.keys(this.$route.query).reduce((acc, key) => {
        // 如果 key 轉小寫後，符合 consumerid, sourcesystemcode, nonce，就帶上
        if (["consumerid", "sourcesystemcode", "nonce"].includes(key.toLowerCase())) {
          acc[key.toLowerCase()] = this.$route.query[key];
        }

        return acc;
      }, {});

      let alertMessage = '';

      if (params.sourcesystemcode !== undefined) {
        alertMessage += `sourcesystemcode: ${params.sourcesystemcode}\n`;
      }

      if (params.consumerid !== undefined) {
        alertMessage += `consumerid: ${params.consumerid}\n`;
      }

      if (alertMessage !== '') {
        alert(alertMessage);
      }

      let response = await registerApi.checkRegistered(params);

      let next = response.data.next ?? "unknown"

      return this.goNext(next);
    },
    async goNext(next) {
      switch (next) {
        case "registered_page":
          await this.fetchMe();
          this.goSuccess();
          return false;
        case "success_page":
          await this.fetchMe();
          this.$router.push({
            name: "LiffRegisterWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
          });
          return false;
        case "form_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (! ['LiffRegisterMember', 'LiffRegisterTerms'].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffRegisterMember",
              params: {
                orgCode: this.$route.params.orgCode,
              },
              query: {
                from: this.$route.query.from || (this.$route.name === 'LiffRegisterVerifyForm' ? 'verify_form' : ''),
              },
            });
            return false;
          }
          break;
        case "verify_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (! ['LiffRegisterValidate'].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffRegisterValidate",
              params: {
                orgCode: this.$route.params.orgCode,
              },
              query: {
                from: this.$route.name === 'LiffRegisterVerifyForm' ? 'verify_form' : '',
              },
            });
            return false;
          }
          break;
        case "verify_form_page":
          if (! ['LiffRegisterTerms', 'LiffRegisterValidate', 'LiffRegisterVerifyForm'].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffRegisterVerifyForm",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "qdm_register":
        case "external_register":
          this.$router.push({
            name: "LiffRegisterNotRegister",
          });
          return false;
        case "check_overwrite_binding":
          this.$router.push({
            name: "LiffRegisterOverwriteCheck",
          });
          return false;
        case "field_missed":
          this.$router.push({
            name: "LiffRegisterInfo",
            params: {
              type: "field_missed"
            }
          });
          return false;
        case "provider_error":
          this.$router.push({
            name: "LiffRegisterProviderError",
          });
          return false;
        case "bypass_remind_page":
          await this.fetchMe();
          this.$router.push({
            name: "LiffRegisterBypassRemind",
          });
          return false;
        default:
          return true;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    goSuccess() {
      var registeredAction = this.getModuleConfig('registered_action')

      switch (registeredAction) {
        // member-center 前往會員中心
        case "member-center":
          this.$router.push({ name: "LiffMemberCenterHome" });
          break;
        case "member-edit":
          this.$router.push({ name: "LiffMemberCenterEdit" });
          break;
        case "edit_done":
          this.$router.push({ name: "LiffMemberCenterEditDone" });
          break;
        case "thanks":
          this.$router.push({ name: "LiffRegisterThanks" });
          break;
        case "intro":
          this.$router.push({ name: "LiffRegisterIntro" });
          break;
        case "register":
          this.$router.push({ name: "LiffRegisterIndex" });
          break;
        case "welcome":
        default:
          this.$router.push({
            name: "LiffRegisterWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
          });
          break;
      }
    },
  }
}
